@import "palettes";
@import "theme";
@import "mixins";

.two_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: $phone) {
    grid-template-columns: 1fr;
  }
}
